//
// Copyright 2018 Google Inc.
//
// Permission is hereby granted, free of charge, to any person obtaining a copy
// of this software and associated documentation files (the "Software"), to deal
// in the Software without restriction, including without limitation the rights
// to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
// copies of the Software, and to permit persons to whom the Software is
// furnished to do so, subject to the following conditions:
//
// The above copyright notice and this permission notice shall be included in
// all copies or substantial portions of the Software.
//
// THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
// IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
// FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
// AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
// LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
// OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
// THE SOFTWARE.
//

@use "sass:list";
@use "@material/animation/variables" as animation-variables;
@use "@material/ripple/mixins" as ripple-mixins;
@use "@material/theme/variables" as theme-variables; // for mdc-theme-accessible-ink-color
@use "@material/theme/mixins" as theme-mixins;
@use "@material/shape/mixins" as shape-mixins;
@use "@material/shape/functions";
@use "./variables";
@use "@material/rtl/mixins" as rtl-mixins;

//
// Public
//

@mixin ink-color($color) {
  @include theme-mixins.prop(color, $color);
}

@mixin fill-color($color) {
  @include theme-mixins.prop(background-color, $color);
}

@mixin fill-color-accessible($color) {
  @include fill-color($color);
  @include ink-color(theme-variables.accessible-ink-color($color));
}

@mixin icon-ink-color($color) {
  .mdc-top-app-bar__action-item,
  .mdc-top-app-bar__navigation-icon {
    @include theme-mixins.prop(color, $color);
    @include ripple-mixins.states($color);
  }
}

@mixin short-shape-radius($radius, $rtl-reflexive: true) {
  @if list.length($radius) > 1 {
    @error "Invalid radius: '#{$radius}' component doesn't allow customizing all corners";
  }

  $radius: 0 0 $radius 0;

  $selector: if(&, "&.mdc-top-app-bar--short-collapsed", ".mdc-top-app-bar--short-collapsed");

  #{$selector} {
    @include shape-mixins.radius(
      functions.resolve-percentage-radius(variables.$dense-row-height, $radius),
      $rtl-reflexive
    );
  }
}

//
// Private
//

@mixin mobile-breakpoint_($mobile-breakpoint: variables.$mobile-breakpoint) {
  @media (max-width: $mobile-breakpoint) {
    .mdc-top-app-bar__row {
      height: variables.$mobile-row-height;
    }

    .mdc-top-app-bar__section {
      padding: variables.$mobile-section-padding;
    }

    .mdc-top-app-bar--short {
      transition: width 200ms animation-variables.$standard-curve-timing-function;
    }

    .mdc-top-app-bar--short-collapsed {
      transition: width 250ms animation-variables.$standard-curve-timing-function;

      .mdc-top-app-bar__section--align-end {
        @include rtl-mixins.reflexive-box(padding, right, variables.$short-collapsed-right-icon-padding);
      }
    }

    .mdc-top-app-bar--prominent {
      .mdc-top-app-bar__title {
        padding-bottom: variables.$prominent-mobile-title-bottom-padding;
      }
    }

    .mdc-top-app-bar--fixed-adjust {
      padding-top: variables.$mobile-row-height;
    }
  }
}
