//
// Copyright 2019 Google Inc.
//
// Permission is hereby granted, free of charge, to any person obtaining a copy
// of this software and associated documentation files (the "Software"), to deal
// in the Software without restriction, including without limitation the rights
// to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
// copies of the Software, and to permit persons to whom the Software is
// furnished to do so, subject to the following conditions:
//
// The above copyright notice and this permission notice shall be included in
// all copies or substantial portions of the Software.
//
// THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
// IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
// FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
// AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
// LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
// OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
// THE SOFTWARE.
//

@use "@material/feature-targeting/functions";
@use "@material/feature-targeting/mixins" as feature-targeting-mixins;
@use "@material/rtl/mixins" as rtl-mixins;
@use "@material/theme/mixins" as theme-mixins;
@use "@material/typography/mixins" as typography-mixins;
@use "./variables";

@mixin core-styles($query: functions.all()) {
  $feat-color: functions.create-target($query, color);
  $feat-structure: functions.create-target($query, structure);

  .mdc-form-field {
    @include typography-mixins.typography(body2, $query);

    @include feature-targeting-mixins.targets($feat-color) {
      @include theme-mixins.prop(color, text-primary-on-background);
    }

    @include feature-targeting-mixins.targets($feat-structure) {
      display: inline-flex;
      align-items: center;
      vertical-align: middle;
    }

    // stylelint-disable-next-line selector-max-type
    > label {
      @include feature-targeting-mixins.targets($feat-structure) {
        @include rtl-mixins.reflexive-property(margin, 0, auto);
        @include rtl-mixins.reflexive-property(padding, variables.$item-spacing, 0);

        order: 0;
      }
    }
  }

  .mdc-form-field--align-end {
    // stylelint-disable-next-line selector-max-type
    > label {
      @include feature-targeting-mixins.targets($feat-structure) {
        @include rtl-mixins.reflexive-property(margin, auto, 0);
        @include rtl-mixins.reflexive-property(padding, 0, variables.$item-spacing);

        order: -1;
      }
    }
  }
}
