//
// Copyright 2017 Google Inc.
//
// Permission is hereby granted, free of charge, to any person obtaining a copy
// of this software and associated documentation files (the "Software"), to deal
// in the Software without restriction, including without limitation the rights
// to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
// copies of the Software, and to permit persons to whom the Software is
// furnished to do so, subject to the following conditions:
//
// The above copyright notice and this permission notice shall be included in
// all copies or substantial portions of the Software.
//
// THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
// IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
// FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
// AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
// LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
// OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
// THE SOFTWARE.
//

@use "sass:list";
@use "@material/feature-targeting/functions";
@use "@material/feature-targeting/mixins" as feature-targeting-mixins;
@use "@material/animation/functions" as functions2;
@use "@material/animation/variables" as animation-variables;
@use "@material/rtl/mixins" as rtl-mixins;
@use "@material/typography/mixins" as typography-mixins;
@use "@material/typography/variables" as variables2;
@use "@material/theme/mixins" as theme-mixins;
@use "@material/theme/variables" as theme-variables;
@use "@material/list/mixins" as list-mixins;
@use "@material/ripple/mixins" as ripple-mixins;
@use "@material/shape/mixins" as shape-mixins;
@use "@material/elevation/mixins" as elevation-mixins;
@use "./variables";

//
// Public
//

@mixin core-styles($query: functions.all()) {
  $feat-structure: functions.create-target($query, structure);
  $feat-animation: functions.create-target($query, animation);

  // postcss-bem-linter: define drawer
  .mdc-drawer {
    @include title-ink-color(variables.$title-ink-color, $query: $query);
    @include subtitle-ink-color(variables.$subtitle-ink-color, $query: $query);
    @include border-color(variables.$divider-color, $query: $query);
    @include surface-fill-color(variables.$surface-fill-color, $query: $query);
    @include item-icon-ink-color(variables.$item-inactive-ink-color, $query: $query);
    @include item-text-ink-color(variables.$item-inactive-ink-color, $query: $query);
    @include item-activated-icon-ink-color(variables.$item-activated-ink-color, $query: $query);
    @include item-activated-text-ink-color(variables.$item-activated-ink-color, $query: $query);
    @include shape-radius(large, $query: $query);
    @include item-shape-radius(4px, $query: $query);
    @include z-index(variables.$z-index, $query: $query);
    @include width(variables.$width, $query: $query);

    @include feature-targeting-mixins.targets($feat-structure) {
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      box-sizing: border-box;
      height: 100%;
      /* @noflip */
      border-right-width: 1px;
      /* @noflip */
      border-right-style: solid;
      overflow: hidden;

      @include rtl-mixins.rtl {
        /* @noflip */
        border-right-width: 0;
        /* @noflip */
        border-left-width: 1px;
        /* @noflip */
        border-right-style: none;
        /* @noflip */
        border-left-style: solid;
      }
    }

    @include feature-targeting-mixins.targets($feat-animation) {
      transition-property: transform;
      transition-timing-function: animation-variables.$standard-curve-timing-function;
    }

    @include list-item_($query);
  }

  .mdc-drawer--animate {
    @include feature-targeting-mixins.targets($feat-structure) {
      transform: translateX(-100%);

      @include rtl-mixins.rtl {
        transform: translateX(100%);
      }
    }
  }

  .mdc-drawer--opening {
    @include feature-targeting-mixins.targets($feat-structure) {
      transform: translateX(0);

      // Required to level the specificity with animate class.
      @include rtl-mixins.rtl {
        transform: translateX(0);
      }
    }

    @include feature-targeting-mixins.targets($feat-animation) {
      transition-duration: variables.$animation-enter;
    }
  }

  .mdc-drawer--closing {
    @include feature-targeting-mixins.targets($feat-structure) {
      transform: translateX(-100%);

      @include rtl-mixins.rtl {
        transform: translateX(100%);
      }
    }

    @include feature-targeting-mixins.targets($feat-animation) {
      transition-duration: variables.$animation-exit;
    }
  }

  .mdc-drawer__header {
    @include feature-targeting-mixins.targets($feat-structure) {
      flex-shrink: 0;
      box-sizing: border-box;
      min-height: 64px; // same as $mdc-top-app-bar-row-height
      padding: 0 variables.$surface-padding 4px;
    }
  }

  .mdc-drawer__title {
    @include typography-mixins.typography(headline6, $query: $query);
    @include typography-mixins.baseline-top(36px, $query: $query);
    @include typography-mixins.baseline-bottom(20px, $query: $query);
  }

  .mdc-drawer__subtitle {
    @include typography-mixins.typography(body2, $query: $query);
    @include typography-mixins.baseline-top(20px, $query: $query);

    @include feature-targeting-mixins.targets($feat-structure) {
      margin-bottom: 0;
    }
  }

  .mdc-drawer__content {
    @include feature-targeting-mixins.targets($feat-structure) {
      height: 100%;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
    }
  }

  // postcss-bem-linter: end
}

@mixin dismissible-core-styles($query: functions.all()) {
  $feat-structure: functions.create-target($query, structure);

  .mdc-drawer--dismissible {
    @include feature-targeting-mixins.targets($feat-structure) {
      @include rtl-mixins.reflexive-position(left, 0);

      display: none;
      position: absolute;

      &.mdc-drawer--open {
        display: flex;
      }
    }
  }

  .mdc-drawer-app-content {
    @include feature-targeting-mixins.targets($feat-structure) {
      @include rtl-mixins.reflexive-box(margin, left, 0);

      position: relative;
    }
  }
}

@mixin modal-core-styles($query: functions.all()) {
  $feat-structure: functions.create-target($query, structure);
  $feat-animation: functions.create-target($query, animation);

  .mdc-drawer--modal {
    @include scrim-fill-color(variables.$modal-scrim-color, $query: $query);
    @include elevation-mixins.elevation(variables.$modal-elevation, $query: $query);

    @include feature-targeting-mixins.targets($feat-structure) {
      @include rtl-mixins.reflexive-position(left, 0);

      display: none;
      position: fixed;
    }

    &.mdc-drawer--open {
      @include feature-targeting-mixins.targets($feat-structure) {
        display: flex;
      }
    }
  }

  .mdc-drawer-scrim {
    @include feature-targeting-mixins.targets($feat-structure) {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: variables.$z-index - 1;
    }

    @include feature-targeting-mixins.targets($feat-animation) {
      transition-property: opacity;
      transition-timing-function: animation-variables.$standard-curve-timing-function;
    }

    .mdc-drawer--open + & {
      @include feature-targeting-mixins.targets($feat-structure) {
        display: block;
      }
    }

    .mdc-drawer--animate + & {
      @include feature-targeting-mixins.targets($feat-structure) {
        opacity: 0;
      }
    }

    .mdc-drawer--opening + & {
      @include feature-targeting-mixins.targets($feat-animation) {
        transition-duration: variables.$animation-enter;
      }

      @include feature-targeting-mixins.targets($feat-structure) {
        opacity: 1;
      }
    }

    .mdc-drawer--closing + & {
      @include feature-targeting-mixins.targets($feat-animation) {
        transition-duration: variables.$animation-exit;
      }

      @include feature-targeting-mixins.targets($feat-structure) {
        opacity: 0;
      }
    }
  }
}

@mixin activated-overlay-color($color, $query: functions.all()) {
  :not(.mdc-list--non-interactive) > .mdc-list-item {
    @include ripple-mixins.states-activated($color, $query: $query);
  }
}

@mixin border-color($color, $query: functions.all()) {
  $feat-color: functions.create-target($query, color);
  $value: rgba(theme-variables.prop-value($color), variables.$divider-opacity);

  @include feature-targeting-mixins.targets($feat-color) {
    @include theme-mixins.prop(border-color, $value);
  }
}

@mixin item-shape-radius($radius, $rtl-reflexive: true, $query: functions.all()) {
  @include list-mixins.single-line-shape-radius($radius, $rtl-reflexive, $query: $query);
}

@mixin shape-radius($radius, $query: functions.all()) {
  @if list.length($radius) > 2 {
    @error "Invalid radius: '#{$radius}' component doesn't allow customizing all corners";
  } @else if list.length($radius) == 2 {
    $radius: 0 list.nth($radius, 1) list.nth($radius, 2) 0;
  } @else {
    $radius: 0 $radius $radius 0;
  }

  @include shape-mixins.radius($radius, $rtl-reflexive: true, $query: $query);
}

@mixin divider-color($color, $query: functions.all()) {
  $value: rgba(theme-variables.prop-value($color), variables.$divider-opacity);

  @include list-mixins.divider-color($value, $query: $query);
}

@mixin title-ink-color($color, $query: functions.all()) {
  $feat-color: functions.create-target($query, color);
  $value: rgba(theme-variables.prop-value($color), variables.$title-ink-opacity);

  .mdc-drawer__title {
    @include feature-targeting-mixins.targets($feat-color) {
      @include theme-mixins.prop(color, $value);
    }
  }
}

@mixin subtitle-ink-color($color, $query: functions.all()) {
  $feat-color: functions.create-target($query, color);
  $value: rgba(theme-variables.prop-value($color), variables.$subtitle-ink-opacity);

  @include list-mixins.group-subheader-ink-color($value, $query: $query);

  .mdc-drawer__subtitle {
    @include feature-targeting-mixins.targets($feat-color) {
      @include theme-mixins.prop(color, $value);
    }
  }
}

@mixin fill-color-accessible($color, $query: functions.all()) {
  $accessibleColor: theme-variables.accessible-ink-color($color);

  @include title-ink-color($accessibleColor, $query: $query);
  @include subtitle-ink-color($accessibleColor, $query: $query);
  @include item-text-ink-color($accessibleColor, $query: $query);
  @include item-icon-ink-color($accessibleColor, $query: $query);
  @include surface-fill-color($color, $query: $query);
}

@mixin item-icon-ink-color($color, $query: functions.all()) {
  $value: rgba(theme-variables.prop-value($color), variables.$item-inactive-icon-ink-opacity);

  @include list-mixins.item-graphic-ink-color($value, $query: $query);
}

@mixin item-activated-icon-ink-color($color, $query: functions.all()) {
  $value: rgba(theme-variables.prop-value($color), variables.$item-active-icon-ink-opacity);

  .mdc-list-item--activated {
    @include list-mixins.item-graphic-ink-color($value, $query: $query);
  }
}

@mixin item-activated-text-ink-color($color, $query: functions.all()) {
  $value: rgba(theme-variables.prop-value($color), variables.$item-active-text-ink-opacity);

  .mdc-list-item--activated {
    @include list-mixins.item-primary-text-ink-color($value, $query: $query);
  }
}

@mixin item-text-ink-color($color, $query: functions.all()) {
  $value: rgba(theme-variables.prop-value($color), variables.$item-inactive-text-ink-opacity);

  .mdc-list-item {
    @include list-mixins.item-primary-text-ink-color($value, $query: $query);
  }
}

@mixin surface-fill-color($color, $query: functions.all()) {
  $feat-color: functions.create-target($query, color);
  $value: theme-variables.prop-value($color);

  @include feature-targeting-mixins.targets($feat-color) {
    @include theme-mixins.prop(background-color, $value);
  }
}

@mixin scrim-fill-color($color, $query: functions.all()) {
  $feat-color: functions.create-target($query, color);
  $value: rgba(theme-variables.prop-value($color), variables.$modal-scrim-opacity);

  + .mdc-drawer-scrim {
    @include feature-targeting-mixins.targets($feat-color) {
      @include theme-mixins.prop(background-color, $value);
    }
  }
}

@mixin z-index($value, $query: functions.all()) {
  $feat-structure: functions.create-target($query, structure);

  @include feature-targeting-mixins.targets($feat-structure) {
    z-index: $value;
  }
}

@mixin width($width, $query: functions.all()) {
  $feat-structure: functions.create-target($query, structure);

  @include feature-targeting-mixins.targets($feat-structure) {
    width: $width;
  }

  &.mdc-drawer--open:not(.mdc-drawer--closing) + .mdc-drawer-app-content {
    @include feature-targeting-mixins.targets($feat-structure) {
      @include rtl-mixins.reflexive-box(margin, left, $width);
    }
  }
}

//
// Private
//
@mixin list-item_($query: functions.all()) {
  $feat-structure: functions.create-target($query, structure);

  // postcss-bem-linter: ignore
  .mdc-list-item {
    @include typography-mixins.typography(subtitle2, $query: $query);

    @include feature-targeting-mixins.targets($feat-structure) {
      height: calc(48px - 2 * #{variables.$list-item-spacing});
      // To accomodate margin conflict.
      margin: (variables.$list-item-spacing * 2) 8px;
      padding: 0 variables.$surface-padding / 2;
    }
  }

  // postcss-bem-linter: ignore
  .mdc-list-item:nth-child(1) {
    @include feature-targeting-mixins.targets($feat-structure) {
      margin-top: 2px;
    }
  }

  // postcss-bem-linter: ignore
  .mdc-list-item:nth-last-child(1) {
    @include feature-targeting-mixins.targets($feat-structure) {
      margin-bottom: 0;
    }
  }

  // postcss-bem-linter: ignore
  .mdc-list-group__subheader {
    @include typography-mixins.typography(body2, $query: $query);
    @include typography-mixins.baseline-top(24px, $query: $query);

    @include feature-targeting-mixins.targets($feat-structure) {
      margin: 0;
      padding: 0 variables.$surface-padding;
    }
  }

  // postcss-bem-linter: ignore
  .mdc-list-divider {
    @include feature-targeting-mixins.targets($feat-structure) {
      margin: 3px 0 4px;
    }
  }

  // Prevents list item children from being included in the click target.
  // postcss-bem-linter: ignore
  .mdc-list-item__text,
  .mdc-list-item__graphic {
    @include feature-targeting-mixins.targets($feat-structure) {
      pointer-events: none;
    }
  }
}
